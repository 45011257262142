<template>
  <div>
    <div class="card">
      <div class="card-header border-0">
        <h3 class="mb-0 text-left">Basic Profile <small>(User ID: {{data.userId}})</small></h3>
      </div>
      <div class="card-body">
        <form @submit.prevent="submitHandler">
          <div class="row">
            <div :class="field.colClass" :key="key" class="form-group" v-for="(field, key) in schema">

              <template v-if="field.type === 'checkbox'">
                <span class="form-control-label">{{ field.label }}</span> <br>
                <label class="custom-toggle">
                  <input
                      :required="field.required"
                      type="checkbox"
                      v-model="data[field.model]"
                  />
                  <span class="custom-toggle-slider rounded-circle" data-label-off="No"
                        data-label-on="Yes"></span>
                </label>
              </template>

              <template v-if="field.type === 'select'">
                <label :for="field.label" class="form-control-label">{{ field.label }}</label>
                <select
                    :multiple="field.multiple"
                    :required="field.required"
                    :size="field.size"
                    class="form-control"
                    v-model="data[field.model]"
                >
                  <option :key="i" :value="option.value" v-for="(option, i) in dropdowns[field.options]">
                    {{ option.label }}
                  </option>
                </select>
              </template>

              <template v-else-if="field.type === 'date'">
                <label :for="field.label" class="form-control-label">{{ field.label }}</label>
                <input type="date" class="form-control" v-model="data[field.model]" max="2010-12-31">
              </template>

              <template v-else>
                <label :for="field.label" class="form-control-label">{{ field.label }}</label>
                <input
                    :id="field.label"
                    :required="field.required"
                    :type="field.type"
                    :readonly="field.readonly"
                    class="form-control"
                    v-model="data[field.model]"
                />
                <!--                          <small v-if="submitted && getError(field.model)" class="text-danger">required</small>-->
                <div class="invalid-feedback">{{ errors }}</div>
              </template>
            </div>
          </div>
        </form>
      </div>
    </div>

    <PlayerFiles :data="files" :id="id"/>
  </div>
</template>


<script>
import {mapGetters} from "vuex";
import {required} from "vuelidate/lib/validators";
import PlayerFiles from './PlayerFiles';

export default {
  name: "PlayerBasicProfile",
  components: {PlayerFiles},
  props: ['schema', 'data', 'id'],

  validations() {
    return {
      data: this.fieldsValidation
    }
  },

  data() {
    return {
      submitted: false,
      dropdowns: {
        countriesDropdown: [],
        GendersDropdown: [{value: '0', label: 'Male'}, {value: '1', label: 'Female'}],
      }
    }
  },

  computed: {
    ...mapGetters(['errors']),
    ...mapGetters('common', ['getCountries']),

    files() {
      if (!this.data.files) return [];
      return Object.assign([], this.data.files);
    },

    fieldsValidation() {
      const items = {}
      for (const info of this.schema) {
        items[info.model] = {required}
      }
      return items;
    },
  },

  async mounted() {
    await this.$store.dispatch('common/loadCountries')
    this.dropdowns.countriesDropdown = this.getCountries
  },

  methods: {
    getError(field) {
      return this.$v.data[field].$invalid;
    },

    async submitHandler() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) return;

      this.data.id = this.id;
      await this.$store.dispatch('player/updateProfile', this.data);
    }
  }
}
</script>
