<template>
      <BasicProfile v-if="profile" :schema="schema.profile" :data="profile" :id="id" />
</template>

<script>
import { mapGetters } from "vuex";
import schema from '@/store/in-memory-db/forms/player.json';
import BasicProfile from '@/components/player/BasicProfile';

export default {
  name: "editPlayer",
  components: {BasicProfile},

  data() {
    return {
      schema,
      id: this.$route.params.id
    }
  },

  computed: {
    ...mapGetters('player', ['playerProfile']),

    profile() {
      if(!this.playerProfile)  return ;
      return Object.assign({}, this.playerProfile);
    },

  },

  mounted() {
    this.$store.dispatch('player/getProfile', {id: this.id})
  }

}
</script>
