<template>
  <div>

    <h3 class="mb-0 text-left">Activity Files ({{ data.length }})</h3>
    <div class="row">
      <div class="card col" v-if="data.length === 0">
        <div class="card-body">
          <div class="text-info">No Files found for this user</div>
        </div>
      </div>

      <div v-for="(file, i) in data" :key="i" class="col-xl-3 col-lg-6">
        <div class="card card-stats">
          <div class="card-body">
            <div class="row">
              <div class="col">
                <span class="h2 font-weight-bold mb-0 text-uppercase">{{ file.type }}
                  <small>({{ getExt(file.url) }})</small></span>
                <p class="h3"> <small class="font-weight-bold">
                    Sport Type: </small>
                  <small class="text-uppercase">{{ file.Sport ? file.Sport : "Cycling" }} </small>
                </p>
                <p class="mt-3 mb-0 text-sm text-info mr-2">Dated: {{ file.created_at | dateFormat }}</p>
              </div>
              <div class="col-auto">
                <button class="btn btn-outline-light icon icon-shape" @click="downloadFile(file._id, file.url)">
                  <i class="ni ni-cloud-download-95"></i>
                </button>
              </div>
              <!-- <small>{{file.url}}</small> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import * as dayjs from 'dayjs'

export default {
  name: "PlayerFiles",
  props: ['data', 'id'],

  data() {
    return {}
  },

  filters: {
    dateFormat: function (value) {
      return dayjs(value).format('DD/MMM/YYYY hh:mm A');
    }
  },

  methods: {
    downloadFile(id, url) {
      this.$store.dispatch('player/downloadFile', { id }).then(res => {
        if (res && res.status) {
          this.downloadUserFile(res.data, this.getExt(url));
          return;
        }

        alert('Error download file. Please try again')
      });
    },

    downloadUserFile(data, ext) {
      if (ext !== 'fit') {
        data = "data:text/json;charset=utf-8," + encodeURIComponent(data);
      }

      const node = document.createElement('a');
      node.setAttribute("href", data);
      node.setAttribute("download", `activity.${ext}`);
      document.body.appendChild(node); // required for firefox
      node.click();
      node.remove();
    },

    getExt(url) {
      return url.split('.').pop();
    }

  }
}
</script>
